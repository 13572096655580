<template>
  <drawer-layout
    :title="isNew ? '新建' : info.templateName"
    :show="show"
    :isForm="isNew"
    permission="platform:termtemplate:button:edit"
    @edit="editForm"
    @closed="closed"
    @save="saveTempInfo"
  >
    <div class="info-group">
      <g-title>基本信息</g-title>
      <info-item :label-width="labelWidth" label="名称">{{ info.templateName }}</info-item>
      <info-item :label-width="labelWidth" label="序号">{{ info.sequenceNo }}</info-item>
      <info-item :label-width="labelWidth" label="简介">{{ info.description }}</info-item>
      <!-- <info-item label="适用部门">
            <div class="flex flex-wrap pt-1">
              <a-tag v-for="(item,index) in info.departments" :key="index" class="mr-3 mb-3">{{item.departName}}</a-tag>
            </div>
          </info-item> -->
      <g-title>正文</g-title>

      <a-row :gutter="16" v-for="(item, index) in info.fields" :key="index">
        <a-col :span="8" class="py-3">{{ item.fieldName }}</a-col>
        <a-col :span="4" class="py-3">
          <div v-if="item.fieldClassId == 1">文本</div>
          <div v-if="item.fieldClassId == 2">图片</div>
          <div v-if="item.fieldClassId == 3">数字</div>
          <div v-if="item.fieldClassId == 4">日期</div>
        </a-col>
        <a-col :span="4" class="py-3">
          <div>{{ item.isRequired ? '必选' : '可选' }}</div>
        </a-col>
        <a-col :span="4" class="py-3">
          <div>{{ item.isKeyword ? '关键字' : '非关键字' }}</div>
        </a-col>
      </a-row>
    </div>
    <div class="form-wrap" slot="form">
      <a-form-model ref="templateForm" layout="horizontal" :model="templateForm" :rules="rules" v-bind="layout">
        <g-title>基本信息</g-title>

        <a-form-model-item label="名称" prop="templateName">
          <a-input v-model="templateForm.templateName" placeholder="请输入模版名" v-if="!isNew" />
          <a-auto-complete
            v-model="templateForm.templateName"
            placeholder="请输入模版名"
            v-else
            @change="templateNameChanged"
          >
            <template slot="dataSource">
              <a-select-option v-for="item in templateList" :key="item.templateName + ''">{{
                item.templateName
              }}</a-select-option>
            </template>
          </a-auto-complete>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input-number v-model="templateForm.sequenceNo" placeholder="请输入序号" class="w-full" />
        </a-form-model-item>
        <a-form-model-item label="说明" prop="description">
          <a-textarea autoSize v-model="templateForm.description" placeholder="请输入说明内容" />
        </a-form-model-item>
        <!-- <a-form-model-item label="适用部门" >
            <role-tree 
              :defaultDepartKeys="defaultDepartKeys" 
              @change="treeChange"
            />
          </a-form-model-item> -->

        <g-title>正文</g-title>
        <a-row :gutter="16">
          <a-col :span="8"> <div class="py-3">*项目</div> </a-col>
          <a-col :span="4"> <div class="py-3">类型</div> </a-col>
          <a-col :span="4"> <div class="py-3">是否必选</div> </a-col>
          <a-col :span="4"> <div class="py-3">是否关键字</div> </a-col>
          <a-col :span="4"> <div class="py-3">操作</div> </a-col>
        </a-row>
        <a-row :gutter="16" v-for="(item, index) in templateForm.fields" :key="index" class="mb-6">
          <a-col :span="8">
            <a-auto-complete v-model="item.fieldName" placeholder="请输入项目标题" :filterOption="filterOption">
              <template slot="dataSource">
                <a-select-option v-for="item in typeTitleList" :key="item.text">{{ item.text }}</a-select-option>
              </template>
            </a-auto-complete>
          </a-col>
          <a-col :span="4">
            <a-select :default-value="1" v-model="item.fieldClassId" placeholder="选择类型">
              <a-select-option v-for="itm in typesList" :key="Number(itm.value)">{{ itm.text }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="4" class="pt-1">
            <a-switch checked-children="是" un-checked-children="否" default-checked v-model="item.isRequired" />
          </a-col>
          <a-col :span="4" class="pt-1">
            <a-switch checked-children="是" un-checked-children="否" default-checked v-model="item.isKeyword" />
          </a-col>
          <a-col :span="4">
            <a-button shape="circle" icon="minus" class="mr-3" v-if="index > 0" @click="removeField(index)" />
            <a-button shape="circle" icon="plus" @click="addField" />
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { getAction, postAction, putAction } from '@/api/manage'
import { queryIdTree, ajaxGetDictItems } from '@/api/api'
import RoleTree from '@/views/system/components/RoleTree'
// import {DrawerMixins} from '@/mixins/DrawerMixins'
const defaultFields = {
  fieldName: '',
  fieldClassId: '',
  isRequired: false,
  isKeyword: false,
}
export default {
  // mixins: [DrawerMixins],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    id: [Number, String],
    platformId: [Number, String],
    templateList: {
      type: Array,
      default: [],
    },
  },
  components: {
    RoleTree,
  },
  watch: {
    show(val) {
      this.getDetail()
      this.getDicts()
      if (this.isNew) {
        this.oldFormData = JSON.parse(JSON.stringify(this.templateForm))
      }
    },
  },
  data() {
    return {
      info: { templateName: '', fields: [], departments: [], description: '', sequenceNo: 1 },
      rules: {
        description: [{ required: true, message: '请输入简介内容', trigger: 'blur' }],
        templateName: [{ required: true, message: '请输入模版名', trigger: 'blur' }],
      },
      labelWidth: '50',
      layout: {
        labelCol: { style: 'width: 50px;', span: 4 },
        wrapperCol: { span: 20 },
      },
      isEdit: false,
      isSaved: false,
      templateForm: {
        sequenceNo: '',
        description: '',
        templateName: '',
        fields: [Object.assign({}, defaultFields)],
        departments: [],
      },
      typesList: [],
      typeTitleList: [],
      tempClassList: [],

      departList: [], // 存储部门信息
      defaultDepartKeys: [],
    }
  },
  methods: {
    closed() {
      const self = this
      if (this.isSaved) {
        this.$emit('closed', false)
        this.isEdit = false
        this.resetForm()
      } else {
        let newData = JSON.stringify(this.templateForm)
        let oldData = JSON.stringify(this.oldFormData)
        if (newData != oldData) {
          this.$confirm({
            title: '提示',
            content: '您尚未保存数据，确定要退出吗？',
            onOk() {
              self.$emit('closed', false)
              self.isEdit = false
              self.resetForm()
            },
          })
        } else {
          self.$emit('closed', false)
          self.isEdit = false
          self.resetForm()
        }
      }
    },

    getDetail() {
      getAction('/terms/get_template', { id: this.id }).then((res) => {
        console.log(res)
        if (res.success && res.data) {
          this.info = res.data
          this.isSaved = true
        }
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0
    },
    editForm() {
      this.isEdit = true
      this.isSaved = false
      const { templateName, fields, departments, description, sequenceNo } = this.info
      this.templateForm = {
        templateName: templateName,
        fields: fields,
        description: description,
        sequenceNo: sequenceNo,
      }
      this.oldFormData = JSON.parse(JSON.stringify(this.templateForm))
      if (departments.length < 1) return
      this.templateForm.departments = departments
      this.defaultDepartKeys = departments.map((item) => item.departId)
    },
    saveTempInfo() {
      this.$refs['templateForm'].validate((valid) => {
        console.log(this.templateForm)
        this.templateForm.platformId = this.platformId
        if (valid) {
          if (this.isEdit) {
            this.templateForm.templateId = this.id
            putAction('/platform/edit_terms_template', this.templateForm).then((res) => {
              if (res.success) {
                this.isEdit = false
                this.$message.success(res.message)
                this.isSaved = true
                this.closed()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            postAction('/platform/add_terms_template', this.templateForm).then((res) => {
              console.log(res)
              if (res.success) {
                this.$message.success('创建成功！')
                this.isSaved = true
                this.closed()
              }
            })
          }
        } else {
          this.$message.error('请检查输入内容')
          return false
        }
      })
    },
    treeChange(keys, list) {
      console.log(list)
      this.departList = list
      this.templateForm.departments = list.map((item) => {
        return {
          departId: item.key,
          departName: item.title,
        }
      })
    },
    templateNameChanged(value) {
      if (this.isNew) {
        let matched = this.templateList.filter((one) => one.templateName == value)
        if (matched && matched.length > 0) {
          let one = matched[0]
          this.templateForm.sequenceNo = one.sequenceNo;
          this.templateForm.description = one.description;
          this.templateForm.fields = Object.assign([], one.fields);
          this.$forceUpdate()
        }
      }
    },

    resetForm() {
      // this.$refs['templateForm'].resetFields();
      this.templateForm = {
        sequenceNo: '',
        description: '',
        templateName: '',
        fields: [Object.assign({}, defaultFields)],
        departments: [],
      }
    },

    addField() {
      this.templateForm.fields.push(Object.assign({}, defaultFields))
    },
    removeField(index) {
      if (this.templateForm.fields.length <= 1) return
      this.templateForm.fields.splice(index, 1)
    },
    getDicts() {
      ajaxGetDictItems('terms_template_type').then((res) => {
        this.typesList = res.data
      })
      ajaxGetDictItems('terms_template_field').then((res) => {
        this.typeTitleList = res.data
      })
    },
  },
  created() {},
}
</script>

<style lang='stylus'></style>