var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.isNew ? "新建" : _vm.info.templateName,
        show: _vm.show,
        isForm: _vm.isNew,
        permission: "platform:termtemplate:button:edit",
      },
      on: { edit: _vm.editForm, closed: _vm.closed, save: _vm.saveTempInfo },
    },
    [
      _c(
        "div",
        { staticClass: "info-group" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "名称" } },
            [_vm._v(_vm._s(_vm.info.templateName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.info.sequenceNo))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "简介" } },
            [_vm._v(_vm._s(_vm.info.description))]
          ),
          _c("g-title", [_vm._v("正文")]),
          _vm._l(_vm.info.fields, function (item, index) {
            return _c(
              "a-row",
              { key: index, attrs: { gutter: 16 } },
              [
                _c("a-col", { staticClass: "py-3", attrs: { span: 8 } }, [
                  _vm._v(_vm._s(item.fieldName)),
                ]),
                _c("a-col", { staticClass: "py-3", attrs: { span: 4 } }, [
                  item.fieldClassId == 1
                    ? _c("div", [_vm._v("文本")])
                    : _vm._e(),
                  item.fieldClassId == 2
                    ? _c("div", [_vm._v("图片")])
                    : _vm._e(),
                  item.fieldClassId == 3
                    ? _c("div", [_vm._v("数字")])
                    : _vm._e(),
                  item.fieldClassId == 4
                    ? _c("div", [_vm._v("日期")])
                    : _vm._e(),
                ]),
                _c("a-col", { staticClass: "py-3", attrs: { span: 4 } }, [
                  _c("div", [
                    _vm._v(_vm._s(item.isRequired ? "必选" : "可选")),
                  ]),
                ]),
                _c("a-col", { staticClass: "py-3", attrs: { span: 4 } }, [
                  _c("div", [
                    _vm._v(_vm._s(item.isKeyword ? "关键字" : "非关键字")),
                  ]),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "form-wrap", attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "templateForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.templateForm,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c("g-title", [_vm._v("基本信息")]),
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "templateName" } },
                [
                  !_vm.isNew
                    ? _c("a-input", {
                        attrs: { placeholder: "请输入模版名" },
                        model: {
                          value: _vm.templateForm.templateName,
                          callback: function ($$v) {
                            _vm.$set(_vm.templateForm, "templateName", $$v)
                          },
                          expression: "templateForm.templateName",
                        },
                      })
                    : _c(
                        "a-auto-complete",
                        {
                          attrs: { placeholder: "请输入模版名" },
                          on: { change: _vm.templateNameChanged },
                          model: {
                            value: _vm.templateForm.templateName,
                            callback: function ($$v) {
                              _vm.$set(_vm.templateForm, "templateName", $$v)
                            },
                            expression: "templateForm.templateName",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "dataSource" },
                            _vm._l(_vm.templateList, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.templateName + "" },
                                [_vm._v(_vm._s(item.templateName))]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input-number", {
                    staticClass: "w-full",
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.templateForm.sequenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "sequenceNo", $$v)
                      },
                      expression: "templateForm.sequenceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "说明", prop: "description" } },
                [
                  _c("a-textarea", {
                    attrs: { autoSize: "", placeholder: "请输入说明内容" },
                    model: {
                      value: _vm.templateForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "description", $$v)
                      },
                      expression: "templateForm.description",
                    },
                  }),
                ],
                1
              ),
              _c("g-title", [_vm._v("正文")]),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "py-3" }, [_vm._v("*项目")]),
                  ]),
                  _c("a-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "py-3" }, [_vm._v("类型")]),
                  ]),
                  _c("a-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "py-3" }, [_vm._v("是否必选")]),
                  ]),
                  _c("a-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "py-3" }, [_vm._v("是否关键字")]),
                  ]),
                  _c("a-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "py-3" }, [_vm._v("操作")]),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.templateForm.fields, function (item, index) {
                return _c(
                  "a-row",
                  { key: index, staticClass: "mb-6", attrs: { gutter: 16 } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "a-auto-complete",
                          {
                            attrs: {
                              placeholder: "请输入项目标题",
                              filterOption: _vm.filterOption,
                            },
                            model: {
                              value: item.fieldName,
                              callback: function ($$v) {
                                _vm.$set(item, "fieldName", $$v)
                              },
                              expression: "item.fieldName",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "dataSource" },
                              _vm._l(_vm.typeTitleList, function (item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.text },
                                  [_vm._v(_vm._s(item.text))]
                                )
                              }),
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: {
                              "default-value": 1,
                              placeholder: "选择类型",
                            },
                            model: {
                              value: item.fieldClassId,
                              callback: function ($$v) {
                                _vm.$set(item, "fieldClassId", $$v)
                              },
                              expression: "item.fieldClassId",
                            },
                          },
                          _vm._l(_vm.typesList, function (itm) {
                            return _c(
                              "a-select-option",
                              { key: Number(itm.value) },
                              [_vm._v(_vm._s(itm.text))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { staticClass: "pt-1", attrs: { span: 4 } },
                      [
                        _c("a-switch", {
                          attrs: {
                            "checked-children": "是",
                            "un-checked-children": "否",
                            "default-checked": "",
                          },
                          model: {
                            value: item.isRequired,
                            callback: function ($$v) {
                              _vm.$set(item, "isRequired", $$v)
                            },
                            expression: "item.isRequired",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { staticClass: "pt-1", attrs: { span: 4 } },
                      [
                        _c("a-switch", {
                          attrs: {
                            "checked-children": "是",
                            "un-checked-children": "否",
                            "default-checked": "",
                          },
                          model: {
                            value: item.isKeyword,
                            callback: function ($$v) {
                              _vm.$set(item, "isKeyword", $$v)
                            },
                            expression: "item.isKeyword",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 4 } },
                      [
                        index > 0
                          ? _c("a-button", {
                              staticClass: "mr-3",
                              attrs: { shape: "circle", icon: "minus" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeField(index)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("a-button", {
                          attrs: { shape: "circle", icon: "plus" },
                          on: { click: _vm.addField },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }